<template>
  <div class="home">
    <a-layout theme="light">
      <a-layout-header>
        <div class="header_content">
          <div class="header_content-left">
            <span>i彩仓后台</span>
          </div>
          <div class="header_content-right">
            <span>{{state.name}}</span>
            <span>{{state.type}}</span>
            <span @click="cancel">
              <ExportOutlined twoToneColor="#666" />
            </span>
          </div>
        </div>
      </a-layout-header>
      <a-layout class="home_content">
        <a-layout-sider>
          <a-menu
            class="myMenu"
            width="60px"
            mode="inline"
            v-model:selectedKeys="state.selectedKeys"
            v-model:openKeys="state.openKeys"
          >
            <template v-for="(item) in navs" :key="item.meta.key">
              <a-sub-menu :key="item.meta.key" v-if="item.children">
                <template v-slot:title>
                  <span>{{item.meta.text}}</span>
                </template>
                <a-menu-item
                  v-for="(nav) in item.children"
                  :key="nav.meta.key"
                  @click="handleClick(nav)"
                >{{nav.meta.text}}</a-menu-item>
              </a-sub-menu>
              <a-menu-item :key="item.meta.key" @click="handleClick(item)" v-else>
                <span>{{item.meta.text}}</span>
              </a-menu-item>
            </template>
          </a-menu>
        </a-layout-sider>
        <a-layout-content>
          <div class="main_content">
            <div class="main_content_title">
              <span>{{title}}</span>
            </div>
            <div class="main_content_body">
              <router-view />
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  reactive,
  onBeforeMount,
  toRefs,
  watch,
  onMounted,
  watchEffect
} from "vue";
import { routes } from "../router/route";
import router from "../router";
import { ExportOutlined } from "@ant-design/icons-vue";
import { getUserInfo, isValid, loginout } from "@/utils/api";
import { message } from "ant-design-vue";
import { redirctTo } from "@/utils/util";
import { useRoute } from "vue-router";

export default {
  components: {
    ExportOutlined
  },
  setup() {
    const navs = routes[0].children;
    const state = reactive({
      navList: [
        {
          text: "权限管理",
          key: "m1",
          child: [
            { text: "角色管理", key: "m1-0" },
            { text: "操作人员", key: "m1-1" }
          ]
        },
        { text: "统计报表管理", key: "2" },
        {
          text: "票的出/入库",
          key: "m2",
          child: [
            { text: "票入库", key: "m2-0" },
            { text: "票出库", key: "m2-1" }
          ]
        },
        {
          text: "设备出/入库",
          key: "m3",
          child: [
            { text: "设备入库", key: "m3-0" },
            { text: "设备出库", key: "m3-1" }
          ]
        },
        { text: "发货", key: "4" },
        { text: "物流、票", key: "5" },
        { text: "设备监控", key: "6" },
        { text: "设备管理", key: "7" },
        { text: "出票等待时间", key: "8" }
      ],
      selectedKeys: [],
      openKeys: [],
      name: "南乔乔",
      type: "管理员",
      title: ""
    });
    // 路径跳转
    function handleClick(e) {
      const route = e.meta && e.meta.route;
      router.push(route);
    }

    function getFather(route) {
      return routes[0].children.filter(item => {
        if (item.children) {
          const isFather = item.children.some(el => {
            if (route.meta.key === el.meta.key) {
              state.selectedKeys = [el.meta.key];
              state.title = el.meta && el.meta.text;
              return true;
            } else {
              return false;
            }
          });
          if (isFather) {
            state.openKeys.push(item.meta.key);
          }
        } else {
          if (item.meta.key == route.meta.key) {
            state.title = item.meta && item.meta.text;
            state.selectedKeys.push(item.meta.key);
          }
        }
      })[0];
    }

    const route = useRoute();
    watchEffect(() => {
      if (route.meta?.text) {
        state.title = route.meta && route.meta.text;
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        if (!state.selectedKeys.includes(route.meta.key)) {
          state.selectedKeys = [route.meta.key];
        }
      }
    });

    onBeforeMount(() => {
      getFather(route);
    });

    async function loadData() {
      try {
        const result = await getUserInfo();
        if (isValid(result)) {
          state.name = result.data.userName;
          state.type = result.data.name;
        }
      } catch (e) {
        console.error(e);
      }
    }
    loadData();
    async function cancel() {
      try {
        const result = await loginout();
        if (result.code == 0) {
          message.success("退出登录成功");
          redirctTo({ path: "/login" });
        }
      } catch (e) {
        console.error(e);
      }
    }
    return {
      ...toRefs(state),
      state,
      navs,
      handleClick,
      cancel
    };
  }
};
</script>
<style lang="scss" scoped>
.main_content {
  min-height: 85vh;
  .main_content_body {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
      background-color: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &_title {
    display: flex;
    justify-content: space-between;
    border-bottom: solid #efefef 1px;
    margin-bottom: 30px;
    padding: 10px 0 20px 0;
    span {
      padding-left: 10px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        border-left: solid 3px #1890ff;
        width: 5px;
        height: 14px;
        left: 0;
        top: 4px;
      }
    }
  }
}
.home_content {
  position: absolute;
  min-width: 1200px;
  top: 64px;
  left: 0;
  right: 0;
}
.header {
  &_content {
    position: fixed;
    top: 0;
    background: #fff;
    min-width: 1200px;
    padding: 0 30px;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    &-left {
      font-size: 30px;
      color: #1890ff;
    }
    &-right {
      span {
        color: #1890ff;
        font-size: 12px;
        margin-left: 3px;
        &:nth-child(2) {
          display: inline;
          background: orange;
          color: #fff;
          padding: 3px 10px;
          border-radius: 5px;
        }
        &:last-child {
          display: inline-block;
          margin-left: 10px;
          font-size: 18px;
          vertical-align: -2px;
        }
      }
    }
  }
}
.ant-layout-sider {
  text-align: left;
}
.ant-layout-header {
  background: #fff;
}
.ant-layout-sider {
  margin: 15px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}
.ant-layout-content {
  background: #fff;
  margin: 15px 0;
  border-radius: 5px;
  overflow-y: auto;
  padding: 10px 20px;
}
.ant-menu-item::after {
  border: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #fff;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}
.myMenu {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
<style scoped>
.myMenu {
  max-height: calc(100vh - 65px - 30px);
  overflow: auto;
}
.main_content_body {
  max-height: calc(100vh - 65px - 30px - 20px - 53px - 50px);
  overflow: auto;
}
</style>
