import { RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import MainContent from '../views/MainContent.vue'
const constRoute: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {}
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/menu/index.vue')
  }
]
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      menuId: 1,
    },
    children: [
      {
        path: 'author',
        name: 'author',
        meta: {
          text: '权限管理',
          menuId: 2,
          key: 'author'
        },
        component: MainContent,
        children: [
          {
            path: '',
            name: '角色管理',
            meta: {
              text: '角色管理',
              key: 'roleManage',
              menuId: 16,
              route: '/author'
            },
            component: () => import('../views/author/RoleManage.vue')
          },
          {
            path: 'personManage',
            name: '',
            meta: {
              text: '用户管理',
              key: 'personManage',
              menuId: 17,
              route: '/author/personManage'
            },
            component: () => import('../views/author/PersonManage.vue')
          }
        ]
      },
      {
        path: '',
        name: 'machine',
        meta: {
          text: '设备入/出库',
          menuId: 3,
          key:'machine'
        },
        component: MainContent,
        children: [
          {
            path: '',
            name: '设备入库单',
            meta: {
              text: '入库单列表',
              key: 'machineInStrage',
              menuId: 18,
              route: '/'
            },
            component: () => import('../views/machine-in-out-storage/InStorage.vue')
          },
          {
            path: 'outStorage',
            name: '设备出库单',
            meta: {
              text: '出库单列表',
              key: 'machineOutStrage',
              menuId: 19,
              route: '/outStorage'
            },
            component: () => import('../views/machine-in-out-storage/outStorage.vue')
          },
        ],
      },
      {
        path: 'ticketStorage',
        name: 'ticketStorage',
        meta: {
          text: '票入/出库',
          menuId: 22,
          key: 'ticketStorage'
        },
        component: MainContent,
        children: [
          {
            path: '',
            name: '票入库单',
            meta: {
              text: '票入库单列表',
              key: 'ticketStorage',
              menuId: 24,
              route: '/ticketStorage'
            },
            component: () => import('../views/ticket-storage/inStorage.vue')
          },
          {
            path: '/ticketOutStrage',
            name: '票出库单',
            meta: {
              text: '票出库单列表',
              key: 'ticketOutStrage',
              menuId: 23,
              route: '/ticketOutStrage'
            },
            component: () => import('../views/ticket-storage/outStorage.vue')
          },
        ],
      },
      {
        path: 'agentManage',
        name: 'agentManage',
        meta: {
          text: '代理管理',
          menuId: 7,
          key: 'agentManage'
        },
        component: MainContent,
        children: [
          {
            path: '/agentManage',
            name: '代理列表',
            meta: {
              text: '代理列表',
              key: 'agentManage',
              menuId: 25,
              route: '/agentManage'
            },
            component: () => import('../views/agent-manage/agencyList.vue')
          },
          {
            path: '/withdrawList',
            name: '提现列表',
            meta: {
              text: '提现列表',
              key: 'withdrawList',
              menuId: 26,
              route: '/withdrawList'
            },
            component: () => import('../views/agent-manage/withdrawList.vue')
          },
          {
            path: '/importList',
            name: '导入列表',
            meta: {
              text: '导入列表',
              key: 'importList',
              menuId: 27,
              route: '/importList'
            },
            component: () => import('../views/agent-manage/importList.vue')
          },
          {
            path: '/applyList',
            name: '申请列表',
            meta: {
              text: '申请列表',
              key: 'applyList',
              menuId: 28,
              route: '/applyList'
            },
            component: () => import('../views/agent-manage/applyList.vue')
          },
        ],
      },
      {
        path: '',
        name: 'consumer',
        meta: {
          text: '彩民管理',
          menuId: 4,
          key: 'consumer'
        },
        component: MainContent,
        children: [
          {
            path: '/consumer',
            name: '彩民列表',
            meta: {
              text: '彩民列表',
              key: 'consumer',
              menuId: 20,
              route: '/consumer'
            },
            component: () => import('../views/consumer/consumerList.vue')
          },
          {
            path: 'consumerWithdraw',
            name: '彩民提现',
            meta: {
              text: '彩民提现',
              key: 'consumerWithdraw',
              menuId: 21,
              route: '/consumerWithdraw'
            },
            component: () => import('../views/consumer/withdrawList.vue')
          },
          {
            path: 'bonusInfos',
            name: '公告列表',
            meta: {
              text: '公告列表',
              key: 'bonusInfos',
              menuId: 31,
              route: '/bonusInfos'
            },
            component: () => import('../views/consumer/bonusInfoList.vue')
          },
        ]
      },
      {
        path: 'storeManage',
        name: 'storeManage',
        meta: {
          text: '店铺管理',
          key: 'storeManage',
          menuId: 5,
          route: '/storeManage'
        },
        component: () => import('../views/store-manage/index.vue'),
      },
      {
        path: 'shop-purchase-sell-stock-page',
        name: 'shop-purchase-sell-stock-page',
        meta: {
          text: '店铺进销存',
          key: 'shop-purchase-sell-stock-page',
          menuId: 5,
          route: '/shop-purchase-sell-stock-page'
        },
        component: () => import('../views/shopPurchaseSellStock/index.vue'),
      },
      {
        path: 'cabinList',
        name: 'cabinList',
        meta: {
          text: '小屋列表',
          key: 'cabinList',
          menuId: 33,
          route: '/cabinList'
        },
        component: () => import('../views/store-manage/cabinList.vue'),
      },
      {
        path: 'POList',
        name: 'POList',
        meta: {
          text: '采购订单管理',
          key: 'POList',
          menuId: 9,
          route: '/POList'
        },
        component: () => import('../views/PO-manage/index.vue'),
      },
      {
        path: 'machineDeliver',
        name: 'machineDeliver',
        meta: {
          text: '设备配送管理',
          key: 'machineDeliver',
          menuId: 11,
          route: '/machineDeliver'
        },
        component: () => import('../views/machine-deliver/index.vue'),
      },
      {
        path: 'ticketDeliver',
        name: 'ticketDeliver',
        meta: {
          text: '票配送管理',
          key: 'ticketDeliver',
          menuId: 12,
          route: '/ticketDeliver'
        },
        component: () => import('../views/ticket-deliver/index.vue'),
      },
      {
        path: 'machineManage',
        name: 'machineManage',
        meta: {
          text: '设备管理',
          key: 'machineManage',
          menuId: 13,
          route: '/machineManage'
        },
        component: () => import('../views/machine-manage/index.vue'),
      },
      {
        path: 'machineChange',
        name: 'machineChange',
        meta: {
          text: '设备退换',
          key: 'machineChange',
          menuId: 29,
          route: '/machineChange'
        },
        component: () => import('../views/machine-change/index.vue'),
      },
      {
        path: 'delivery',
        name: 'delivery',
        meta: {
          text: '配送人员管理',
          key: 'delivery',
          menuId: 10,
          route: '/delivery'
        },
        component: () => import('../views/delivery/index.vue'),
      },
      {
        path: 'sellTicketOrder',
        name: 'sellTicketOrder',
        meta: {
          text: '出票订单管理',
          key: 'sellTicketOrder',
          menuId: 14,
          route: '/sellTicketOrder'
        },
        component: () => import('../views/sell-ticket-order/index.vue'),
      },
      {
        path: 'redeemList',
        name: 'redeemList',
        meta: {
          text: '兑奖订单管理',
          key: 'redeemList',
          menuId: 15,
          route: '/redeemList'
        },
        component: () => import('../views/redeem-list/index.vue'),
      },
      {
        path: 'statement',
        name: 'statement',
        meta: {
          text: '统计报表',
          key: 'statement',
          menuId: 30,
          route: '/statement'
        },
        component: () => import('../views/statement/index.vue'),
      },
      {
        path: 'monitor',
        name: 'monitor',
        meta: {
          text: '系统监控',
          key: 'monitor',
          menuId: 30,
          route: '/monitor'
        },
        component: () => import('../views/monitor/index.vue'),
      },
      {
        path: '',
        name: 'shop-ticket',
        meta: {
          text: '福小店票管理',
          key: 'shop-ticket',
          menuId: 22,
        },
        component: MainContent,
        children: [
          {
            path: '/shop-ticket-list',
            name: '全部票种',
            meta: {
              text: '全部票种',
              key: 'shop-ticket-list',
              menuId: 22,
              route: '/shop-ticket-list'
            },
            component: () => import('../views/ticket-list/index.vue'),
          },
          // {
          //   path: '/shop-ticket-add',
          //   name: '新增票种',
          //   meta: {
          //     text: '新增票种',
          //     key: 'shop-ticket-add',
          //     menuId: 22,
          //     route: '/shop-ticket-add'
          //   },
          //   component: () => import('../views/ticket-list/add.vue'),
          // },
          {
            path: '/shop-ticket-add',
            name: '新增票种',
            meta: {
              text: '新增票种',
              key: 'shop-ticket-add',
              menuId: 22,
              route: '/shop-ticket-add'
            },
            component: () => import('../views/ticket-list/add.vue'),
          },
        ]
      }
    ]
  }
]

export { constRoute, routes }