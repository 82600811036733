import Interceptors from './interceptor'
import { Request, NormalResponse, Response, Method, Option } from '../model/api'


class HttpService<TRequest extends Request, TResponse extends Response> {

    private axios: any
    private openID: Promise<any> | string | undefined

    constructor() {
        this.axios = new Interceptors().getInterceptors()
    }

    // http请求函数
    private async request(method: any, url: string, params?: TRequest, needOpenID?: true | false, options: Option = { loading: true, text: '数据获取中...', error: false }, responseType = 'json') {

        return new Promise<TResponse>((resolve, reject) => {
            this.axios({
                url: url,
                method:method,
                data: params,
                params:method === 'get'?params:{},
                responseType: responseType
            }).then((res: any) => {
                resolve(res)
            }).catch((err: any) => {
                console.error(url)
                console.error(err.message)
                reject(err)
            }).finally(() => {
                //
            })
        })
    }

    // http get请求
    public get(url: string, params?: any, needOpenID = true, options: Option = { loading: true, text: '数据获取中...', error: false }, responseType = 'json') {
        return this.request('get', url, params, needOpenID, options, responseType)
    }
    // http get请求
    public del(url: string, params?: any, needOpenID = true, options: Option = { loading: true, text: '数据获取中...', error: false }, responseType = 'json') {
        return this.request('delete', url, params, needOpenID, options, responseType)
    }
    // http post请求
    public post(url: string, data?: any, needOpenID = true, options: Option = { loading: true, text: '数据获取中...', error: false }, responseType = 'json') {
        return this.request('post', url, data, needOpenID, options, responseType)
    }

    // http put请求
    public put(url: string, data?: any, needOpenID = true, options: Option = { loading: true, text: '数据获取中...', error: false }) {
        return this.request('put', url, data, needOpenID, options)
    }
}

export default new HttpService<{}, NormalResponse>()