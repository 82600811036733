import { createApp } from 'vue'
import App from '../../src/App.vue'
import {
    Form, 
    Input, 
    Button, 
    Layout,
    Table,
    Menu,
    Modal,
    Tree,
    TreeSelect,
    Select,
    Radio,
    Checkbox,
    Cascader,
    Dropdown,
    Tag,
    DatePicker,
    ConfigProvider,
    Popconfirm,
    Tabs,
    InputNumber,
    Tooltip,
    Transfer,
    Image
    // Dropdown,
    // DropdownButton
} from 'ant-design-vue'

const app = createApp(App)

app
.use(Form)
.use(Input)
.use(Button)
.use(Layout)
.use(Table)
.use(Menu)
.use(Modal)
.use(Tree)
.use(TreeSelect)
.use(Select)
.use(Radio)
.use(Checkbox)
.use(Cascader)
.use(Dropdown)
.use(Tag)
.use(DatePicker)
.use(ConfigProvider)
.use(Popconfirm)
.use(Tabs)
.use(InputNumber)
.use(Tooltip)
.use(Transfer)
.use(Image)

export { app } 