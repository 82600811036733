<template>
    <div class="table_box">
        <a-table 
        :rowKey="(record, index) => {return index}"
        @change="handleTableChange"
        :total="getTotal()"
        :scroll="{ y: scrollY }"
        :pagination="state.pagination"
        :columns="columns" 
        :loading="loading"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :row-selection="showSelection ? rowActions : null"
        :data-source="data">
           <template v-slot:tags="{ text: tags }">
               <a-tag  
               v-for="tag in tags"
               :key="tag"
               :color="tag.length > 4 ? 'geekblue' : 'green'">
               {{tag}}
               </a-tag>
           </template>
           <template v-slot:clickTd="{column, record, text}">
                <a @click="handleTdClick({value:text, key:column.key,record})">{{ text }}</a>
           </template>
           <template v-slot:actions="{disable, record}">
               <div class="button_content"  v-for="(button, index) in buttons" 
                :key="button" >
                <!-- 按钮 -->
                    <a-button
                        v-if="!button.type" 
                        size=small
                        :disabled="record.disable && button.disText"
                        :type="index === 0 ? 'primary' : ''"
                        @click="emitEvent(button.eventName, record)">
                            {{disable}}
                        {{button.disText&&record.disable ? button.disText : button.text}}
                    </a-button>
                    <!-- 下拉按钮 -->
                    <a-dropdown v-if="button.type == 'dropDown'">
                        <template v-slot:overlay>
                            <a-menu>
                                <a-menu-item @click="emitEvent('dropEVent', {eventName: dropItem.eventName, value: record})" v-for="(dropItem, index) in button.drops" :key="index">
                                    {{dropItem.text}}
                                </a-menu-item>
                            </a-menu>
                        </template>
                        <a-button size=small>{{button.text}}<down-outlined /></a-button>
                    </a-dropdown>
               </div>
           </template>
           <template v-slot:image="{record}">
                <img class="img_style" :src="record.mainImg" />
           </template>
           <slot></slot>
        </a-table>
    </div>
</template>
<script>
import { reactive, watch} from 'vue'
import DownOutlined from '@ant-design/icons-vue/DownOutlined'
export default {
    name: 'mTable',
    components: {DownOutlined},
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        buttons: {
            type: Array,
            default: () => {
                return [
                    {text: '删除', disable: true, disbleEventName: '已审核', eventName: 'delete'},
                    {text: '编辑', eventName: 'edit'}
                ]
            }
        },
        totals: {
            type: [Number, String],
            default: 0
        },
        status: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default: () => {
                return [
                    {
                        title: 'name',
                        dataIndex: 'name',
                        key: 'name',
                        slots: {customRender: 'name'}
                    },
                    {
                        title: 'age',
                        dataIndex: 'age',
                        key: 'age',
                        width: 80
                    },
                    {
                        title: 'Tags',
                        key: 'tags',
                        dataIndex: 'tags',
                        slots: { customRender: 'tags' },
                    },
                     {
                        title: 'Address',
                        dataIndex: 'address',
                        key: 'address 1',
                        ellipsis: true,
                    },
                    {
                        title: 'Long Column Long Column Long Column',
                        dataIndex: 'address',
                        key: 'address 2',
                        ellipsis: true,
                    },
                    {
                        title: 'Long Column Long Column',
                        dataIndex: 'address',
                        key: 'address 3',
                        ellipsis: true,
                    },
                    {
                        title: 'manage',
                        dataIndex: 'manage',
                        key: 'manage',
                        slots: {customRender: 'actions'}
                    },
                ]
            }
        },
        data: {
            type: Array,
            default: () => {
                return [
                    {
                        key: '1',
                        name: 'John Brown',
                        age: 32,
                        address: 'New York No. 1 Lake Park, New York No. 1 Lake Park',
                        tags: ['nice', 'developer'],
                    },
                    {
                        key: '2',
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No. 2 Lake Park, London No. 2 Lake Park',
                        tags: ['loser'],
                    },
                    {
                        key: '3',
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney No. 1 Lake Park, Sidney No. 1 Lake Park',
                        tags: ['cool', 'teacher'],
                    },
                ]
            }
        },
        showSelection: {
            type: Boolean,
            default: false
        },
        pageSize: {
            type:  [Number, String],
            default: 10
        },
        currentPage: {
            type: [Number, String],
            default: 1
        },
        scrollY:{
            type: Number,
            default: 470
        }
    },
    setup(props, context) {
        const state = reactive({
           pagination: {
               total: Number(props.totals),
               pageSize: Number(props.pageSize),
               current: Number(props.currentPage)
               }
        })
        watch(props, () => {
            state.pagination.total = Number(props.totals) 
            state.pagination.pageSize = Number(props.pageSize)
            state.pagination.current = Number(props.currentPage)
        })
         function emitEvent(eventName, eventValue) {
            context.emit(eventName, eventValue)
        }
        const rowActions = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
                emitEvent('selectEvent', selectedRows.map((ele) => { return ele.id }))
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                emitEvent('selectEvent', selectedRows.map((ele) => { return ele.id }))
                // console.log(selected, selectedRows, changeRows);
            },
        }
        function handleTableChange(pagination) {
            console.log(pagination)
            emitEvent('pageChange', pagination.current)
        }
        function handleMenuClick(event) {
            console.log(event)
        }
        function getTotal() {
            return Math.ceil(Number(props.totals)/Number(props.pageSize))
        }
        function handleTdClick(e){
           emitEvent('tdClick', e)
        }
        return {
            state,
            rowActions,
            emitEvent,
            handleTableChange,
            handleMenuClick,
            getTotal,
            handleTdClick
        }
    }
}
</script>
<style lang="scss" scoped>
.table_box {
    overflow-y: auto;
}
.button_content {
    display: inline-block;
}

.ant-table-striped :deep .table-striped tr {
  background-color: #fafafa;
}
.img_style{
    width: 80px;
    height: 80px;
}
</style>