/* eslint-disable no-case-declarations */
// import moment from 'moment'
import dayjs from 'dayjs'
import router from '../../router'
import { message, Modal } from 'ant-design-vue'
import { RouteData, MENU } from '../model'
import md5 from 'js-md5'
import axios from 'axios'

export function decodePassword(value: any) {
    return md5(value)
}

export function redirctTo(routeData: RouteData) {
    router.replace(routeData)
}

export function sleep(time = 500) {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve()
        }, time)
    })
}

export function geneIndex(data: any, pageSize: number, currentPage: number) {
    if (data && data.length) {
        data.forEach((ele: any, index: number) => {
            ele.index = (currentPage - 1) * pageSize + index + 1
        })
        return data
    } else {
        return []
    }
}

export async function confirmDialog(title: string, content: string, okText = "确定", cancelText = "取消") {
    try {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: title,
                content: content,
                icon: '',
                cancelText,

                okText,
                onOk() {
                    console.log('onOk');
                    resolve(true)
                },
                onCancel() {
                    console.log('onCancel');
                    resolve(false)
                },
            })
        })
    } catch (e) {
        console.error(e)
    }
}


export function formatTime(time: any, type = 'YYYY-MM-DD HH:mm') {
    if (time) {
        return dayjs(time).format(type)
    }
    return undefined
}

export function showMessage(str: string, type = 'success') {
    switch (type) {
        case 'success':
            message.success(str)
            break
        case 'err':
            message.error(str)
            break
        case 'warn':
            message.warning(str)
            break
        default:
            message.warning(str)
            break
    }
}

export function geneUniId(data: any) {
    if (data && data[0] && data[0].key) {
        return data
    } else {
        data.forEach((ele: any, index: number) => {
            ele.key = index + new Date().getTime()
        })
        return data
    }
}

// 格式化菜单树 
export function formatMenuTree(tree: any, keyName = 'menuId') {
    tree.forEach((ele: any) => {
        ele.key = ele[keyName]
        ele.title = ele.menuName || '主菜单'
        if (ele.childMenus) {
            formatMenuTree(ele.childMenus)
        }
    })
    return tree
}

// 

// 给数组对象赋值
export function assginDataToArray(data: any, array: any) {
    array.forEach((ele: any) => {
        ele.value = ''
        if (ele.prop && data[ele.prop] != void 0) {
            ele.value = data[ele.prop]
        } else if (ele.props) {
            let arr = ''
            ele.props.forEach((propsEle: any) => {
                arr += data[propsEle] || ''
            })
            ele.value = arr
        }
    })
    return array
}


// 获取数组对象里的特定对象
export function findArrObjItem(key: string, value: any, arr: []) {
    const item = arr.filter(ele => ele[key] == value)
    console.log(item)
    return item && item[0] || {}
}

// 获取对象里的值
export function getValueFromObjArr(arr: [], key: any) {
    const obj = new Map()
    arr.forEach((ele) => {
        obj.set(ele[0], ele[1])
    })
    return obj.get(key) || ''
}

// 添加分位
export function appendCent(num = '0') {
    //   let _num = num
    const _num = num.toString().split('.')
    return !_num[1] ? `${_num}.00`
        : _num[1].length == 1 ? `${_num[0]}.${_num[1]}0`
            : _num[1].length == 2 ? num
                : `${_num}.00`
}

export function isEmpty(items: any, labelName = 'value') {
    for (let i = 0; i < items?.length; i++) {
        if (items[i]?.[labelName] == '') {
            showMessage(`${items[i].label}不能为空`)
            return true
        }
    }
    return false
}

// bobl文件下载
export const downloadBobl = (data: any, name = '导出') => {
    const blob = new Blob(
        [data], // 将获取到的二进制文件转成blob
        { type: 'charset=utf-8' } // 有时候打开文档会出现乱码，设置一下字符编码
    )
    // 获取文件名
    const disposition = data['content-disposition']
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    let filename = name + '.xlsx'
    if (matches != null && matches[1]) {
        filename = decodeURIComponent(matches[1].replace(/['"]/g, ''))
    }
    // 转成文件流之后，可以通过模拟点击实现下载效果
    const element = document.createElement('a') // js创建一个a标签
    const href = window.URL.createObjectURL(blob) // 文档流转化成Base64
    element.href = href
    element.download = filename // 下载后文件名
    document.body.appendChild(element)
    element.click() // 点击下载
    document.body.removeChild(element) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
}




export async function dataURItoBlob(dataurl: string, file: File) {
    return new Promise((resolve) => {
        const filename = file.name.split('.')[0]
        const arr = dataurl.split(',')
        const mime = (arr[0].match(/:(.*?);/) as any)[1]
        const suffix = mime.split('/')[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        const miniFile = new File([u8arr], `${filename}.${suffix}`, { type: mime })
        resolve(miniFile)
    })
}

export function formatFileSize(sizeInBytes: any) {
    const KB = 1024
    const MB = KB * 1024
    if (sizeInBytes < KB) {
        return sizeInBytes + ' B'
    } else if (sizeInBytes < MB) {
        return (sizeInBytes / KB).toFixed(2) + ' KB'
    } else {
        return (sizeInBytes / MB).toFixed(2) + ' MB'
    }
}

// 压缩图片
export async function compress(imgFile: File, compressValue: number) {
    return new Promise((resolve) => {
        if (imgFile.size / 1024 / 1024 < 0.1) {
            return showMessage('不支持压缩小于100kb得图片', 'warn')
        }
        const fr = new FileReader()
        fr.readAsDataURL(imgFile)
        fr.onload = () => {
            const img = document.createElement('img')
            img.src = fr.result as string
            // eslint-disable-next-line space-before-function-paren
            img.onload = async () => {
                let width = img.width
                let height = img.height
                const aspectRatio = img.width / img.height
                if (aspectRatio > 1) {
                    width = Math.min(width, img.width)
                    height = width / aspectRatio
                } else {
                    height = Math.min(height, img.height)
                    width = height * aspectRatio
                }
                const canvas = document.createElement('canvas')
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')
                ctx?.drawImage(img, 0, 0, width, height)
                const dataUrl = canvas.toDataURL('image/jpeg', compressValue)
                const res1: any = await dataURItoBlob(dataUrl, imgFile)
                await confirmDialog('提示', `原图大小${formatFileSize(imgFile.size)},压缩后大小${formatFileSize(res1.size)}`)
                resolve(res1)
            }
        }
    })
}


// 图片上传
export function uploadImg(file: File, compressVal = 0) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        if (compressVal) {
            file = await compress(file, compressVal) as File
        } else {
            if (formatFileSize(file.size).includes('MB')) {
                showMessage('图片大于1MB，请压缩上传！', 'warn')
                return
            }
        }

        const hide = message.loading('上传中...', 0);
        const formData = new FormData()
        formData.append('file', file)
        formData.append('dirType', '2')
        console.log(formData, 'formData');
        axios.post('https://api.cqfcmall.com/lottery/oss/upload', formData)
            .then(res => {
                if (res.data?.code == 0)
                    resolve(res.data.data.link)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => hide())
    })
}

export function isJson(str: string) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}


export function deepCopy(obj: any) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = deepCopy(item);
            return arr;
        }, []);
    }

    if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj: any, key) => {
            newObj[key] = deepCopy(obj[key]);
            return newObj;
        }, {});
    }
}

function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getDateRange(mode = 'month') {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    const start = new Date(year, month, date);
    start.setHours(0, 0, 0, 0);

    const end = new Date(year, month, date);
    end.setHours(23, 59, 59, 999);

    switch (mode) {
        case 'day':
            start.setDate(date - 1);
            end.setDate(date);
            break;
        case 'week':
            const lastWeekStart = new Date(start);
            lastWeekStart.setDate(start.getDate() - start.getDay() - 6);
            const lastWeekEnd = new Date(end);
            lastWeekEnd.setDate(end.getDate() - end.getDay());
            return {
                start: formatDate(lastWeekStart),
                end: formatDate(lastWeekEnd)
            };
        case 'month':
            const lastMonthStart = new Date(year, month - 1, 1);
            const lastMonthEnd = new Date(year, month, 0);
            return {
                start: formatDate(lastMonthStart),
                end: formatDate(lastMonthEnd)
            };
        default:
            return null;
    }

    return {
        start: formatDate(start),
        end: formatDate(end)
    };
}